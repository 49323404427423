import {
    TRANSLATION_TITLE_CANCEL_CASE,
    TRANSLATION_TITLE_CANCEL_ACTIVITY,
    TRANSLATION_TITLE_CANCEL_DOC_REQUIRMENT,
    TRANSLATION_TITLE_DELETE_ACTIVITY,
    TRANSLATION_TITLE_REMOVE_TEAM_MEMBER,
    TRANSLATION_TITLE_DELETE_DASHBOARD,
    TRANSLATION_TITLE_DELETE_OPPORTUNITY,
    TRANSLATION_TITLE_DELETE_DOCUMENT,
    TRANSLATION_TITLE_DELETE,
    TRANSLATION_TITLE_SHARE_WITH_CLIENT,
    TRANSLATION_TITLE_DELETE_DOC_TYPE,
} from '@wdx/shared/utils';
import { DialogMode } from '../models/dialog.model';

export const DIALOG_TITLE_MAP = {
    [DialogMode.CancelCase]: TRANSLATION_TITLE_CANCEL_CASE,
    [DialogMode.CancelActivity]: TRANSLATION_TITLE_CANCEL_ACTIVITY,
    [DialogMode.CancelDocumentRequirement]:
        TRANSLATION_TITLE_CANCEL_DOC_REQUIRMENT,
    [DialogMode.DeleteActivity]: TRANSLATION_TITLE_DELETE_ACTIVITY,
    [DialogMode.RemoveTeamMember]: TRANSLATION_TITLE_REMOVE_TEAM_MEMBER,
    [DialogMode.DeleteDashboard]: TRANSLATION_TITLE_DELETE_DASHBOARD,
    [DialogMode.DeleteOpportunity]: TRANSLATION_TITLE_DELETE_OPPORTUNITY,
    [DialogMode.DeleteFiledDocument]: TRANSLATION_TITLE_DELETE_DOCUMENT,
    [DialogMode.Delete]: TRANSLATION_TITLE_DELETE,
    [DialogMode.ShareAppWithClient]: TRANSLATION_TITLE_SHARE_WITH_CLIENT,
    [DialogMode.DeleteDocumentType]: TRANSLATION_TITLE_DELETE_DOC_TYPE,
};
